import AppLogins from './components/AppLogin.vue'
import NavigationMenu from './components/NavigationMenu.vue'
import RequestsGrid from './components/RequestsGrid.vue'
import ReportGrid from './components/ReportGrid.vue'
import LogoutGrid from './components/LogoutGrid.vue'
import Unauthorized from './components/Unauthorized.vue'
import params from '../config'

const routes = [{
        path: '/',
        redirect: '/auth-callback'
    },
    {
        path: '/auth-callback',
        component: AppLogins
    },
    {
        path: '/logout',
        component: LogoutGrid
    },
    {
        path: '/unauthorized',
        component: Unauthorized
    },
    {
        path: '/requests',
        component: NavigationMenu,
        children: [{
                path: '/requests/list',  // regionalMerch
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.RM }
            },
            {
                path: '/requests/regionalLead',  // regionalLead
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.RL }
            },
            {
                path: '/requests/rmapproval',  // regionalPlanningLead
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.RPL }
            },
            {
                path: '/requests/gmreview',  // globalMerch
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.GM }
            },
            {
                path: '/requests/gmapproval',  // globalMerchLead
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.GML }
            },
            {
                path: '/requests/pdapproval',   // productDev
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.PD }
            },
            {
                path: '/requests/spapproval',  // supplyPlanning
                component: RequestsGrid,
                meta: { requiresRole: true, roleType: params.roles.SP }
            },
            {
                path: '/requests/summary',  // summary
                component: RequestsGrid,
                meta: { requiresRole: false, roleType: params.roles.SUM }
            },
            {
                path: '/requests/report',  // summary
                component: ReportGrid,
            }
        ]
    }
];

export default routes;