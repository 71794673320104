<template>
  <div style="text-align:center;margin:15%">
            <h3>User Unauthorized</h3>
            <div>
                <p>
                   You do not have required privileges to access the Gap brief application. Please contact the Levis support.
                </p>
            </div>
        </div>
</template>
<script>
export default {
  name: 'Unauthorized-Page'
}
</script>
