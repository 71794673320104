const AUTHENTICATION_BASE_URL= `${process.env.VUE_APP_AUTHENTICATION_URL}`
const applicationConfiguration = {
    authentication: {
        loginUrl: `${AUTHENTICATION_BASE_URL}/authorize`,
        tokenUrl: "/token",
        logoutUrl: `${AUTHENTICATION_BASE_URL}/logout`,
        introspectUrl: `${AUTHENTICATION_BASE_URL}/v1/introspect`,
        state: "state-8600b31f-52d1-4dca-987c-386e3d8967e9",
        client_id: process.env.VUE_APP_AUTHENTICATION_CLIENT_ID,
        response_type: "code",
        scope: "openid",
        code_challenge_method: "S256",
        grant_type: "authorization_code",
        source: process.env.VUE_APP_AUTH_SOURCE
    }
}
export default applicationConfiguration