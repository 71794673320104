import conf from './applicationConfiguration';
import { parseJwt } from './helpers';
import moment from 'moment';


export function auth_creds(creds) {
    // access token
    return {
        type: 'AUTH_CREDS',
        payload: creds
    }
}



export function set_username(username) {
    return {
        type: 'SET_USERNAME',
        payload: username
    }
}

export function setLocation(location) {
    return {
        type: 'LOCATION_SET',
        payload: location
    }
}

export function setAPIHost(host) {
    return {
        type: 'API_HOST_SET',
        payload: host
    }
}

export function seasonName(seasonName) {
    return {
        type: 'SEASON_NAME',
        payload: seasonName
    }
}

export async function fetchJwt(authCode, redirectUri) {
    const body = {
        code: authCode,
        redirect_uri: redirectUri,
        source: conf.authentication.source,
      }
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    
      try {
        const response = await fetch(conf.authentication.tokenUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
        })
        const data = await response.json()
        localStorage.setItem("tokenexpiryTime", moment().unix() + (data.expires_in) * 1000);
        localStorage.setItem("session_start_time", moment().unix())
        return storeCredentials(data);
      } catch (error) {
        console.log("Error received validating tokenURL: " + error);
      }
}

export async function validateAndRefreshToken(token) {
    const params = new URLSearchParams();
    params.set('token', token);
    params.set('client_id', conf.authentication.client_id);
    return fetch(conf.authentication.introspectUrl, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: params
        })
        .then(response => response.json())
        .then(response => {
            return response.active;
            /*
            //Put this code back once the auth server support refreshtoken grant_type.
            const refreshparams = new URLSearchParams();
            refreshparams.set('grant_type', "refresh_token");
            refreshparams.set('refresh_token', localStorage.getItem("jwtRefreshToken"));
            refreshparams.set('client_id', conf.okta.client_id);
            return fetch(conf.okta.tokenUrl, {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: refreshparams
                })
                .then(response => response.json())
                .then(response => {
                    if ('error' in response) {
                        return null;
                    }
                    return storeCredentials(response);
                })
                
                window.location.href = localStorage.getItem("oktaLogin");
           const parsedCreds = {}
           parsedCreds["token"] = token;
           return parsedCreds;
           */

        }).catch(err => {
            console.log("Error received validating token: " + err);
            return null;
        });
}


// all of these dispatches need to happen together
function storeCredentials(response) {
    const parsedCreds = {}
    parsedCreds["token"] = response.access_token
    parsedCreds["id_token"] = response.id_token
    //TODO: Uncomment after okta grant_type refreshToken is added to okta authserver
        //parsedCreds["refreshToken"] = response.refresh_token
    var username = parseJwt(response.id_token)
    parsedCreds["username"] = username
    return parsedCreds;

    // TODO store response.id_token?
}