<script>
import axios from "axios";
import ApiUris from "../utils/ApiUris";
import clickStream from '../utils/clickStream'
import { base64URLEncode, session } from "../../utils/helpers";
import crypto from "crypto";
import {
  fetchJwt
} from "../../utils/auth_creds_action";
import conf from "../../utils/applicationConfiguration";
import common from '../utils/common';

export default {
  name: "AppLogin",
  data() {
    return {
      username: "",
      password: "",
      ver: base64URLEncode(crypto.randomBytes(32))
    };
  },
  props: {
    msg: String
  },
  beforeMount() {
    // console.log('AppLogin beforeMount');
    this.loggingOut = localStorage.getItem("loggingOut");
    if(this.loggingOut){
      common.pushRoute(this.$router, "/logout");
      return null;
    }
    localStorage.setItem("userAuthenticated", true);
    if (!Object.prototype.hasOwnProperty.call(localStorage, session.VERIFIER_KEY)) {
      localStorage.setItem(session.VERIFIER_KEY, this.ver);
    }
    if (!Object.prototype.hasOwnProperty.call(localStorage, session.CHALLENGE_KEY)) {
      localStorage.setItem(
        session.CHALLENGE_KEY,
        base64URLEncode(
          crypto
            .createHash("sha256")
            .update(this.ver)
            .digest()
        )
      );
    }
    localStorage.setItem(session.REDIRECT_URI, window.location.origin + "/auth-callback"); // TODO add trailing slash?
    this.authCode = new URLSearchParams(window.location.search).get("code");
    this.redirect = !this.authCode;

    // redirect to this okta login if 'code' not present in url
    const params = new URLSearchParams();
    params.set("client_id", conf.authentication.client_id);
    params.set("response_type", conf.authentication.response_type);
    params.set("scope", conf.authentication.scope);
    params.set("redirect_uri", localStorage.getItem(session.REDIRECT_URI));

    params.set("state", conf.authentication.state);
    //params.set("code_challenge_method", conf.okta.code_challenge_method);
    //params.set("code_challenge", localStorage.getItem(session.CHALLENGE_KEY));
    this.oktaLogin = `${conf.authentication.loginUrl}?${params.toString()}`;
    localStorage.setItem("oktaLogin", this.oktaLogin);
    this.state = {
      userAuthorized: null
    };
  },

  mounted() {
    // console.log('AppLogin mounted');
    // debugger;
    if (this.redirect || this.loggingOut) return;

    let redirectUri = localStorage.getItem(session.REDIRECT_URI);
    let verifier = localStorage.getItem(session.VERIFIER_KEY);
    this.fetchJwt(this.authCode, redirectUri, verifier);
  },

  methods: {
    fetchJwt: async function(authCode, redirectUri, verifier) {
      var fetch = fetchJwt(authCode, redirectUri, verifier).then(
        parsedToken => {
          if (parsedToken) {
          clickStream.generateSessionId()
            return parsedToken;
          } else {
            this.redirect = true;
            //this.forceUpdate(); // isAuthenticated should give back false now, so force rerender to redirect to login
          }
        }
      );
      await Promise.all([fetch]).then(response => {
        localStorage.setItem("jwtToken", response[0].token);
        localStorage.setItem("idToken", response[0].id_token);
        
        //clear with each login
        localStorage.removeItem("lookupData");
        
        //localStorage.setItem("jwtRefreshToken", response[0].refreshToken);
        const headers = common.getHeaders(response[0].id_token);
        axios
          .get(ApiUris.getUserRoles, headers)
          .then(response => {
            //Redirecting to the appropriate user role privilege
            localStorage.setItem("userAuthenticated", true);
            var role = "UNKNOWN";
            
            if (response.data.roles.length > 0) {
              role = response.data.roles[0];
            }
            localStorage.setItem('username', response.data.lscoUserName);
            clickStream.initialisation(
              response.data.lscoUserName,
              response.data.name,
              response.data.email
            )
            localStorage.setItem("role", role);
            let region = response.data.region || 'Global';
            if (region === 'undefined' || region === 'null') region = 'Global'   // default to Global for now
            localStorage.setItem("region", region);
            localStorage.setItem('userBrand', response.data.brand || 'All Brands');
            //If user role is not set.
            if (role === "UNKNOWN") {
              common.pushRoute(this.$router, "/unauthorized");
            }
            const urlPath = common.getPathByRole(localStorage.getItem('role'));
            common.pushRoute(this.$router, urlPath);
          })
          .catch(error => {
            // error. When user is not present
            console.error(error);
            localStorage.setItem("userAuthenticated", false);
            common.pushRoute(this.$router, "/unauthorized");
          });
      });
    }
  },
  render() {
    if (this.redirect) {
      window.location.href = this.oktaLogin;  // sort of implicitly returns
    }
    return null;
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.loginForm {
  min-width: 300px;
  max-width: 50%;
  margin: auto;
  margin-top: 10em;
}

.nav-img {
  height: 40px;
  margin-left: 2vw;
  margin-top: -3px;
  float: left;
}

.navbar {
  height: 65px;
  border: 2px solid darkgray;
  background-color: black;
  margin-top: 20px;
  vertical-align: middle;
  padding-top: 15px;
}

.navTitle {
  font-size: 20px;
  color: white;
  margin-left: 15px;
}

.errormsg {
  color: red;
  font-weight: bold;
  margin-left: 8em;
}
</style>