const baseUrl=process.env.VUE_APP_API_URL;

export default {
    getUserRoles: `${baseUrl}/api/user`,
    clickStream: `${baseUrl}/clickstreamapi`,
    productRequest: `${baseUrl}/api/productrequest`,
    updateRequestField: `${baseUrl}/api/productrequest/updateField`,
    reportSummary: `${baseUrl}/api/report`,
    uploadImage: `${baseUrl}/api/upload/uploadfile`,
    // nextId: `${baseUrl}/api/productrequest/nextId`,
    idList: `${baseUrl}/api/productrequest/nextId?batchOf=`,
    summary: `${baseUrl}/api/summary`,
    requestapproval: `${baseUrl}/api/productrequest/changeState`,
    returnRequest: `${baseUrl}/api/productrequest/returnRequest`,
    lookupData: `${baseUrl}/api/lookup/data`,
    logError: `${baseUrl}/api/error/log`,
    uploadExcel: `${baseUrl}/api/upload/uploadrequestfile`,
    lookup: `${baseUrl}/api/lookup `,
    lookupMap: `${baseUrl}/api/lookup/plmValues/`,
    templateRM: `${baseUrl}/loader-template/GAP_RM_Uploader_Template.xlsx`,
    templateGM: `${baseUrl}/loader-template/GAP_GM_Uploader_Template.xlsx`
    // expired_token: "eyJraWQiOiJxMkE5MUtsakhtZkRYdEFCV1Jnc2NLNG5kMUFKcTFlNXRMUXZHbW11TGI0IiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULl9DOG93TXN1cU9TdmJNMlV1MktXX0hERWJyYmQ2R3JoRGc2S1ZMZGo1RWsiLCJpc3MiOiJodHRwczovL2xldmkub2t0YXByZXZpZXcuY29tL29hdXRoMi9hdXNsMDRicm5oaXRkc2lEMTBoNyIsImF1ZCI6Imh0dHBzOi8vZXVyZWthMTE1NTE0MC1lY29tbS1vcGVyYXRvci5maXJlYmFzZWFwcC5jb20vc2lnbmluLXJlZGlyZWN0IiwiaWF0IjoxNTY4NDk1NDg4LCJleHAiOjE1Njg0OTkwODgsImNpZCI6IjBvYW43emdtc3B5Q0FsSDVFMGg3IiwidWlkIjoiMDB1aHU2djRvbnFMWUpKSk4waDciLCJzY3AiOlsib3BlbmlkIl0sInN1YiI6Ijlnb3phbDlAbGV2aS5jb20ifQ.iwCugqXOV4NG5BSJYZy2Uuum2neQpEVJ4A3p6NUD-kA5q9iCal8fyLbh8p308vMz7mBFyCm-gEolaSU6Sv1z-KQ95feoFZRKMRIE2IORVaDJlz9ZKlPXxPwqnqy5pxV5auWRH7w3ug0ADZSVer8nHiRDRK5Q-501GcTxSTnyuueMDtcuuZGhTp2B1kC-vMq2rZd32ob3a7HNEIqikKigrHuD4Rn2s0Y2vsu2jEIE1uIA99bCx1i_XWgYpCJRHhYjZUnu7ZCddwWPLjY4iQCmoS-DWiWxlgeBGm03pQYamOx_pCF6WVvquOkgVY5JMtSJUTvoOrY1ggIQn1cVH_ej6g",
}