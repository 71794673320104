/* eslint-disable no-unused-vars */
import axios from 'axios'
import { v4 } from 'uuid'
import ApiUris from "./ApiUris"
import common from './common'
let gObjectMap ={}
let gUserId = ''
let gConfig = []
let gObjectType = ''
let gTimestamp = ''
let gSubType = ''
let gApiParams = ''
let gApiPayload = ''


async function clickPost () {
  try {
    let token = localStorage.getItem("idToken")
    const headers = common.getHeaders(token);
    gConfig = [
      {
        sessionId: localStorage.getItem('sessionIdGap'),
        userId: gUserId,
        sequenceId: Number(localStorage.getItem('sequenceIdGap')),
        timestamp: gTimestamp,
        objectType: gObjectType,
        objectMap: gObjectMap
      }
    ]
    await axios.post(ApiUris.clickStream, gConfig, headers)
    // await store.api.post({ url: url,
    //   body: gConfig
    // })
  } catch (ex) {
    if (ex.message !== '400') {
      throw ex
    }
  }
}

async function obDevice () {
  gObjectType = 'Device'
  localStorage.setItem(
    'sequenceIdGap',
    Number(localStorage.getItem('sequenceIdGap')) + 1
  )
  let OSName = ''
  let Version = ''
  let nAgt = navigator.userAgent
  let browser = navigator.appName
  let version = '' + parseFloat(navigator.appVersion)
  let majorVersion = parseInt(navigator.appVersion, 10)
  let nameOffset, verOffset, ix

  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browser = 'Opera'
    version = nAgt.substring(verOffset + 6)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
    browser = 'Opera'
    version = nAgt.substring(verOffset + 4)
  }
  // Legacy Edge
  else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
    browser = 'Microsoft Legacy Edge'
    version = nAgt.substring(verOffset + 5)
  }
  // Edge (Chromium)
  else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
    browser = 'Microsoft Edge'
    version = nAgt.substring(verOffset + 4)
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browser = 'Microsoft Internet Explorer'
    version = nAgt.substring(verOffset + 5)
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browser = 'Chrome'
    version = nAgt.substring(verOffset + 7)
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browser = 'Safari'
    version = nAgt.substring(verOffset + 7)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browser = 'Firefox'
    version = nAgt.substring(verOffset + 8)
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') !== -1) {
    browser = 'Microsoft Internet Explorer'
    version = nAgt.substring(nAgt.indexOf('rv:') + 3)
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browser = nAgt.substring(nameOffset, verOffset)
    version = nAgt.substring(verOffset + 1)
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName
    }
  }
  // trim the version string
  if ((ix = version.indexOf(';')) !== -1) { version = version.substring(0, ix) }
  if ((ix = version.indexOf(' ')) !== -1) { version = version.substring(0, ix) }
  if ((ix = version.indexOf(')')) !== -1) { version = version.substring(0, ix) }

  majorVersion = parseInt('' + version, 10)
  if (isNaN(majorVersion)) {
    version = '' + parseFloat(navigator.appVersion)
    majorVersion = parseInt(navigator.appVersion, 10)
  }

  if (navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
    OSName = 'Windows'
    Version = '10.0'
  }
  if (navigator.userAgent.indexOf('Windows NT 6.3') !== -1) {
    OSName = 'Windows 8.1'
    Version = '6.3'
  }
  if (navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
    OSName = 'Windows 8'
    Version = '6.2'
  }
  if (navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
    OSName = 'Windows 7'
    Version = '6.1'
  }
  if (navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
    OSName = 'Windows Vista'
    Version = '6.0'
  }
  if (navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
    OSName = 'Windows XP'
    Version = '5.1'
  }
  if (navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
    OSName = 'Windows 2000'
    Version = '5.0'
  }
  if (navigator.userAgent.indexOf('Mac') !== -1) {
    OSName = 'Mac/iOS'
  }
  if (navigator.userAgent.indexOf('X11') !== -1) {
    OSName = 'UNIX'
  }
  if (navigator.userAgent.indexOf('Linux') !== -1) {
    OSName = 'Linux'
  }

  gObjectMap = {
    systemName: OSName,
    systemVersion: Version,
    screenWidthPixels: screen.width,
    screenHeightPixels: screen.height,
    browserName: browser,
    browserVersion: version
  }

  await clickPost()
}

async function obApplication () {
  gObjectType = 'Application'
  localStorage.setItem(
    'sequenceIdGap',
    Number(localStorage.getItem('sequenceIdGap')) + 1
  )
  gObjectMap = {
    name: 'GapApp',
    fullVersion: 'WEB'
  }
 clickPost()
}

function obEvent (
  type,
  subType,
  apiPath = null,
  apiMethod = null,
  apiResponseHttpCode = null,
  apiPayload = null,
  apiParams = null
) {
  if (
    gSubType !== subType
    // ||
    // gApiParams !== apiParams ||
    // gApiPayload !== apiPayload
  ) {
    if (Number(localStorage.getItem('sequenceIdGap')) > 1) {
      gObjectType = 'Event'
      localStorage.setItem(
        'sequenceIdGap',
        Number(localStorage.getItem('sequenceIdGap')) + 1
      )
      gTimestamp = new Date().toISOString().toString()
      gObjectMap = {
        type: type,
        subType: subType,
        apiHost:
             window.location.href.split('/')[2] === ''
               ? 'gapbrief.io'
               : window.location.href.split('/')[2],
        apiPort: 443,
        apiPath: apiPath,
        apiMethod: apiMethod,
        apiResponseHttpCode: apiResponseHttpCode
      }
      gSubType = subType
      gApiParams = apiParams
      gApiPayload = apiPayload
      clickPost()
    }
  }
}

function generateSessionId () {
  localStorage.setItem('sessionIdGap', v4())
  localStorage.setItem('sequenceIdGap', 1)
}

async function initialisation (lscoUserName, lscoFullName, lscoEmailAddress) {
  gUserId = lscoUserName
  gObjectType = 'User'
  gTimestamp = new Date().toISOString().toString()
  gObjectMap = {
    name: lscoFullName,
    email: lscoEmailAddress
  }
  if (localStorage.getItem('sequenceIdGap') === '1') {
    await clickPost()
    obDevice()
    obApplication()
  }
}
export default {
  generateSessionId,
  initialisation,
  obEvent
}
