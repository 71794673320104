import axios from "axios"
import moment from "moment";
import swal from "sweetalert2";
import ApiUris from "./ApiUris";
import configData from '../../config';
import clickStream from './clickStream'
import validations from "../fieldValidations";

const CREATE_REQUEST = 'CREATE';
const UPDATE_REQUEST = 'UPDATE';
const RETURN_REQUEST = 'RETURN';
const ACTION_REQUEST = 'ACTION';
const lookupData = JSON.parse(localStorage.getItem('lookupData'));

function getHeaders(token) {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    }
  };
}
function getHeadersImage(token) {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": 'multipart/form-data'
    }
  };
}
function fetchImageHeader(token) {
  return {
    responseType: 'arraybuffer',
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    }
  };
}

/**
 * POST/PUT for create/update/return. Returns early if payload is empty.
 * Called by Regional Merch and Global Merch
 * @param {*} payload 
 * @param {*} modifyType 
 * @param {*} vm 
 * @returns 
 */
async function createUpdateReturnRequest(payload, modifyType, vm) {
  if (!payload.length)
    return 0;

  const headers = getHeaders(vm.token);
  try {
    let response;
    switch (modifyType) {
      case CREATE_REQUEST:
        response = await axios.post(ApiUris.productRequest, payload, headers); break;
      case UPDATE_REQUEST:
        response = await axios.put(ApiUris.productRequest, payload, headers); break;
      case RETURN_REQUEST:
        response = await axios.put(ApiUris.returnRequest, payload, headers); break;
      case ACTION_REQUEST:
        response = await axios.post(ApiUris.requestapproval, payload, headers); break;
    }

    let numModified = 0;
    for (let pr of response.data) {
      if (pr.status === 200)
        numModified++
      else
        console.log(`Error in creating Product Request: ${pr.status} - ${pr.errorMessage}`);
    }
    return numModified;

  } catch(error) {
    await handleResponseError(vm, error, 'User was not authorized to update this request');
  }
}


async function fetchLookupData(vm, lookupTypes) {
  let lookupData = []
  lookupData = JSON.parse(localStorage.getItem('lookupData'));
  let allExist = false;
  if (typeof lookupData === 'object' && lookupData !== null) {
    allExist = true;
    
    // check for all keys
    for (const lt of lookupTypes) {
      if (!(lt in lookupData)) {
        allExist = false;
        break;
      }
    }
  }
  
  // if data is not cached in localStorage or a lookup type not found, refetch from api
  if (!allExist) {
    try {
      const resp = await axios.get(`${ApiUris.lookupData}?types=${lookupTypes.join(',')}`, getHeaders(vm.token));
      const lookupRes = await axios.get(`${ApiUris.lookup}`, getHeaders(vm.token));
      const combinedJSON = {
        ...resp.data,
        ...lookupRes.data
      };
      localStorage.setItem('lookupData', JSON.stringify(combinedJSON));
      lookupData = combinedJSON;  // disable require atomic updates
    } catch (error) {
      await handleResponseError(vm, error, `Error fetching ${lookupTypes.join(',')} lookup data`);
    }
  }

  // eslint-disable-next-line
  vm.lookupData = lookupData;
  const schedules = vm.lookupData[configData.lookupTypes.schedules];
  if (schedules) {
    vm.seasonCodes = Array.from(new Set(schedules.map(s => s.season_gtm[0])))
      .sort(seasonComparator);
  }
}

function seasonComparator(a, b) {
  try {
    const [as,ay] = a.split("'");
    const [bs,by] = b.split("'");
    const aa = ay+as;
    const bb = by+bs;
    return bb < aa ? -1 : bb > aa ? 1 : 0;
  } catch (e) { return 0; }
}

/**
 * Fetch requests for grid. Assign to vm.rowData. Generally runs on page load or save
 */
async function fetchGridContents(vm) {
  vm.loading = true;
  const headers = getHeaders(vm.token);

  // summary view only
  if (vm.role === configData.roles.SUM) {
    try {
      // eslint-disable-next-line
      vm.rowData = (await axios.get(ApiUris.summary, headers)).data;
      setFilters(JSON.parse(localStorage.getItem(vm.filterCacheName)), vm);
      vm.loading = false;
    } catch(error) {
      await handleResponseError(vm, error, 'Error fetching Summary data');
    }
    return;
  }

  // if admin super user, send current role-specific route info to api to correctly filter product request
  let uri = ApiUris.productRequest;
  if (localStorage.getItem('role') === configData.roles.SUPER) {
    const routeRole = vm.role.replaceAll(' ', '-');
    uri += `?adminRole=${routeRole}`;
  }
  
  try {
    const response = await axios.get(uri, headers);
    // initial data filtering
    let filteredRequests = response.data;

    // filter out "Saved" (not yet RM-submitted requests) if role is not Regional Merch. These would be requests 
    // that were created by RM and have autosaved fields but were not submitted for RL
    if (vm.role !== configData.roles.RM) {
      filteredRequests = filteredRequests.filter(item => item.submittedBy);
    }

    switch (vm.role) {
      
      case configData.roles.RM:
        filteredRequests = filteredRequests.filter(function(item) {
          // keep requests if state=0 and (its not more_info or its moreinforeturnrole is not Regional Lead bc they share same state)
          return item.currentState === "0" && (item.lastAction !== "MORE_INFO" || item.moreInfoReturnRole !== configData.roles.RL)
        });
        filteredRequests.forEach(function(row) {
          if (row.lastAction === "REJECT") {
            if (row.activity.length > 0) {
              var activity = row.activity;
              var comment = activity[activity.length - 1].comments;
              row["rejectedComments"] = comment;
            }
          }
        });
        break;
      
      case configData.roles.RL:
        filteredRequests = filteredRequests.filter(function(item) {
          return item.lastAction !== "MORE_INFO" || item.moreInfoReturnRole !== configData.roles.RM
        });
        break;
      
      case configData.roles.RPL:
        break;
      
      case configData.roles.GM:
        filteredRequests = filteredRequests.filter(function(item) {
          // keep requests where not asking for more_info OR moreinfo_ASSIGNED_role is not GML bc they share same state)
          return item.currentState !== "0" && (item.lastAction !== "MORE_INFO" || item.moreInfoReturnRole !== configData.roles.GML)
        });
        // RIO-13 add this post-fetch property for status display to distinguish these stage 2 requests from "New"
        for (const row of filteredRequests)
          row.submittedToGML = !isEmptyStr(row.globalMerchant)
                                && validations.actualPC9(row.actualPC9)
                                && (
                                    row.requestType !== 'New' ||
                                    (
                                        validations.fabricCode(row.faCode)
                                        && validations.generalTextValidation(row.gapType)
                                        && validations.generalTextValidation(row.description)
                                    )
                                );
        break;
      
      case configData.roles.GML:
        filteredRequests = filteredRequests.filter(function(item) {
          return item.lastAction !== "MORE_INFO" || item.moreInfoReturnRole !== configData.roles.GM
        });
        break;
      
      case configData.roles.PD:
        filteredRequests.forEach(function(row) {
          if (row.confirmationDate == undefined) {
            row["confirmationDate"] = moment().format("MM/DD/YYYY");
          }
        });
        break;
      
      case configData.roles.SP:
        filteredRequests.forEach(function(row) {
          if (row.poIssuanceDate != undefined) {
            row["poIssuanceDate"] = moment(row.poIssuanceDate).format("MM/DD/YYYY");
          }
          
          if (row.confirmationDate != undefined) {
            row["confirmationDate"] = moment(row.confirmationDate).format("MM/DD/YYYY");
          } else{row["confirmationDate"] = moment().format("MM/DD/YYYY")}
          if (row.exFactoryDate != undefined) {
            row["exFactoryDate"] = moment(row.exFactoryDate).format("MM/DD/YYYY");
          }
          if (row.inDCDate != undefined) {
            row["inDCDate"] = moment(row.inDCDate).format("MM/DD/YYYY");
          }

          row["avgTimeDiff"] = dateDifference2(
            row["inDCDate"],
            row["exFactoryDate"]
          );

          row["leadTime"] = parseInt(
            dateDifference(row["exFactoryDate"], row["poIssuanceDate"])
          );
          if (isNaN(row["leadTime"])) {
            row["leadTime"] = 0;
          }
        });
        break;
    }
    
    vm.rowData = filteredRequests;
    
    // filter final data by statuses
    setInitialAgFilters(vm);
    vm.loading = false;
  } catch(error) {
    await handleResponseError(vm, error, 'Error in fetching grid contents');
  }
}

function dateDifference(start, end) {
  var startDate = moment(start, "MM/DD/YYYY");
  var endDate = moment(end, "MM/DD/YYYY");
  var diff = Math.ceil(moment.duration(startDate.diff(endDate)).asDays()); 
  return diff;
}

function dateDifference2(start, end) {
  var startDate = moment(start, "MM/DD/YYYY");
  var endDate = moment(end, "MM/DD/YYYY");
  var res = moment.duration(startDate.diff(endDate)).asDays();
  return isNaN(res) ? 0 : res;
}

/**
 * Create initial filters based on role, called from fetch Grid Contents
 * @param {*} vm 
 */
function setInitialAgFilters(vm) {
  let filterModel = JSON.parse(localStorage.getItem(vm.filterCacheName));
  
  if (filterModel === undefined || filterModel === null || Object.keys(filterModel).length === 0) {
    console.debug('setting filter');
    switch(vm.role) {
      case configData.roles.RM:
        break;
      case configData.roles.RL:
        filterModel = {
          lastAction: {values: ["MORE_INFO", "New"],type: "set"},
        };
        break;
      case configData.roles.RPL:
        filterModel = {
          lastAction: ["MORE_INFO", "NEW"],
        };
        break;
      case configData.roles.GM:
        filterModel = {
          lastAction: ["New", "MORE_INFO", "Waiting for Data Review"],
        };
        break;
      case configData.roles.GML:
        filterModel = { 
          lastAction: ["MORE_INFO", "NEW"],
        };
        break;
      case configData.roles.PD:
        filterModel = { 
          lastAction: ["NEW", "MORE_INFO"],
        };
        break;
      case configData.roles.SP:
      default:
        break;
    }
    
    // RIO-198 all user roles should have initial cluster filters set
    // vm's region == user's region
    if (vm.region !== 'Global') {
      let clusters = [vm.region];
      if (vm.region in configData.datafields.regionClusterMap)
        clusters = clusters.concat(configData.datafields.regionClusterMap[vm.region]);
      if (!filterModel) filterModel = {};
      filterModel.requestingRegion = clusters;
    }
    
  } else {
    console.debug('using same filter');
  }

  setFilters(filterModel, vm);
}

function setFilters(filter, vm) {
  setTimeout(() => {
    // console.debug('setting filter model: ', filter);
    console.debug('filtermodel', filter)
    vm.gridApi.setFilterModel(filter);
    vm.gridApi.onFilterChanged();
  }, 200);
}

/** Modal prompt */
async function hasMoreInfoReq(vm) {
  vm.loading = false;
  return await swal
    .fire({
      text:
        "Submission of a MORE_INFO request will send this request back to its previous approval state. Please confirm if you wish to proceed.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    });
}


async function returnReportData(vm) {
  let token = localStorage.getItem("idToken")
  const headers = getHeaders(token);
  vm.loading = true;
  try {
    const response = await axios.get(`${ApiUris.reportSummary}?brand=${vm.brandTrack}&season=${vm.seasonTrack}&role=${vm.roleTrack}`, headers);
    // eslint-disable-next-line
    vm.loading = false;
    return response.data
  } catch(error) {
    await handleResponseError(vm, error, 'Error fetching Report data');
  }
}

async function handleResponseError(vm, error, errorMsg) {
  vm.loading = false;
  let sendErrorForLogging = true;
  if (error.message && (error.message.toLowerCase().startsWith('network error') || error.message.includes('401'))) {
    errorMsg = errorMsg || 'User was not authorized'
    if (error.response && error.response.data && error.response.data.errorMsg) {
      // api authorization error
      await fireErrorModal(errorMsg);
    } else {
      // okta session expiration
      sendErrorForLogging = false;
      await fireLogoutModal(vm)
    }
  } else {
    errorMsg = errorMsg || 'Unknown error occurred';
    if (error.message)
      errorMsg += ' : ' + error.message;
    if (error.response && error.response.data && error.response.data.errorMsg)
      errorMsg += ' : ' + error.response.data.errorMsg;
    await fireErrorModal(errorMsg);
  }
  console.error("Error occurred", errorMsg);

  // send error back to server and log
  if (sendErrorForLogging) {
    try {
      const payload = {
        createStamp: new Date().toISOString(),
        message: errorMsg,
        username: vm.username,
      }
      await axios.post(ApiUris.logError, payload, getHeaders(vm.token));
    } catch (error) {
      console.error('Error logging error back to server: ', error);
    }
  }
}
 
async function fireLogoutModal(vm) {
  vm.loading = false;
  await swal.fire({
    text: "Your session is expired, please re-login",
    type: "info",
    confirmButtonText: "Ok"
  });
  pushRoute(vm.$router, "/logout");
}

function fireErrorModal(msg) {
  swal.fire({
    title: msg,
    type: "error",
    confirmButtonText: "Ok"
  });
}


function autosave(vm, payload) {
  const headers = getHeaders(vm.token);
  let axiosPromise;
  axiosPromise = axios.post(ApiUris.updateRequestField, payload, headers);
  return axiosPromise.then(response => {
    return response
  }).catch(error => {
    handleResponseError(vm, error, 'User was not authorized to update this request');
  });
}

async function fetchImage(path) {
  let token = localStorage.getItem("idToken")
  const headers = fetchImageHeader(token);
  try {
    const resp = await axios.get(`${path}`,headers);
    return 'data:image/jpeg;base64,' + Buffer.from(resp.data, 'binary').toString('base64');
  } catch (e) {
    console.error(`Fetch image failed: ${e.message}`);
    return null;
  }
}


async function uploadImage(payload, vm) {
  let token = localStorage.getItem("idToken")
  const headers = getHeadersImage(token);
  try {
    return axios.post(ApiUris.uploadImage, payload, headers);
  } catch(error) {
    await handleResponseError(vm, error, 'Error uploading image');
  }
}

async function uploadExcel(payload, vm) {
  let token = localStorage.getItem("idToken")
  const headers = getHeadersImage(token);
  try {
    return axios.post(ApiUris.uploadExcel, payload, headers);
  } catch(error) {
    await handleResponseError(vm, error, 'Error uploading Excel');
  }
}

/**
 * Called by non RM/GM
 * @param {*} transformToPayload function to convert data into final payload before making POST request
 */
async function approveRequest(vm, roleSpecificValidation, transformToPayload) {
  vm.loading = true;
  vm.gridApi.stopEditing();
  const rows = vm.gridApi.getSelectedNodes();

  // check at least a row is selected
  if (!rows.length) {
    vm.loading = false;
    swal.fire({ title: "Please Select a Request", type: "error" });
    return;
  }

  // check approval state is selected
  for (let row of rows) {
    if (row.data["approval_state"] === undefined) {
      vm.loading = false;
      swal.fire({
        title: "Please select Aprove or Reject for all the Selected Requests ",
        type: "error",
        confirmButtonText: "Ok"
      });
      return;
    }
  }
  
  // check missing fields
  if (!roleSpecificValidation(rows)) {
    vm.loading = false;
    swal.fire({
      type: "error",
      text: "Please fill the missing values/Invalid fields in the selected rows",
      confirmButtonText: "Ok"
    });
    return;
  }

  // role specific remap of row data for api
  const transformedPayload = transformToPayload(rows);
  
  // map approval_state and toState correctly to action, moreInfoState and moreInfoRole for api
  for (let i = 0; i < rows.length; i++) {
    let error = true;
    for (const tranRow of transformedPayload) {
      if (tranRow.id === rows[i].data.id) {
        error = false;
        const approvalState = rows[i].data.approval_state;
        if (approvalState) {
          tranRow.action = approvalState.toUpperCase();
          if (tranRow.action === 'MORE_INFO') {
            tranRow.moreInfoState = configData.states[rows[i].data.toState];  // state number (mapped into currentState in graph)
            tranRow.moreInfoRole = rows[i].data.toState;  // role name (mapped to moreInfoReturnRole in graph)
          }
        }
      }
    }

    if (error) throw 'Assert error on modifying transform';
  }
  
  const headers = getHeaders(vm.token);
  try {
    const response = await axios.post(ApiUris.requestapproval, transformedPayload, headers);
    var count = response.data.length;
    // eslint-disable-next-line
    vm.loading = false;
    clickStream.obEvent('Submit', `Submitted by ${vm.role}`)
    swal.fire({
      text: " Requests Updated : " + count,
      type: "success",
      confirmButtonText: "Ok"
    });
    await fetchGridContents(vm);
    vm.gridApi.deselectAll();
  } catch(error) {
    await handleResponseError(vm, error, 'Error in fetching grid contents');
  }
}


function pushRoute(router, urlPath) {
  // might implicitly return here
  router.push(urlPath);
}

function getPathByRole(role) {
  switch (role) {
    case configData.roles.SUPER:
    case configData.roles.RM:
      return "/requests/list";
    case configData.roles.RL:
      return "/requests/regionalLead";
    case configData.roles.RPL:
      return "/requests/rmapproval";
    case configData.roles.GM:
      return "/requests/gmreview";
    case configData.roles.GML:
      return "/requests/gmapproval";
    case configData.roles.PD:
      return "/requests/pdapproval";
    case configData.roles.SP:
      return "/requests/spapproval";
    case configData.roles.VO:
      return "/requests/summary";
  }
}

function getRoleByPrevRouteIfSuperUser() {
  const currentRoute = localStorage.getItem("route");
  if (!currentRoute) return configData.roles.RM;
  for (const [role, route] of Object.entries(configData.roleToRoute)) {
    if (route === currentRoute) return role;
  }
}

function assignToRoleDropdownValues() {
  let currentRole = localStorage.getItem("role");
  if (currentRole === configData.roles.SUPER) {
    currentRole = getRoleByPrevRouteIfSuperUser();
  }
  const states = configData.states;
  const current_state = states[currentRole];
  const eligible_states = [];

  for (const roleName in states) {
    if (states[roleName] < current_state) {
      eligible_states.push(roleName);
    }
  }

  return { values: eligible_states };
}

/** @returns true if (!s or s.length==0) */
function isEmptyStr(s) { return !s || s.length === 0; }

/**
 * Returned filtered subclasses conditionally based on row data
 * RIO-32
 * @param {*} subclasses 
 * @param {*} row 
 */
function filterSubclasses(subclasses, row) {
  if (row.data.gtmStage === 'SRT #2') {
    return {values: subclasses.filter(x => !(x in configData.subclassesExcludedFromSrt))}
  } else {
    return {values: subclasses};
  }
}

/** Return date in MM/DD/YYYY format. If invalid, return empty string */
function dateValueGetter(val) {
  let newVal = '';
  if (val) {
    newVal = moment(val).format("MM/DD/YYYY");
    if (newVal === 'Invalid date') newVal = '';
  }
  // console.log('newval', newVal)
  return newVal;
}

/** Limit brand dropdown list to user's assigned brand, unless there is no assignment or is Super User */
function getBrandDropdownList(vm) {
  // RIO-21
  if(lookupData && lookupData.brand) {
  const brands = extractValues(lookupData.brand)
  return vm.actualRole !== configData.roles.SUPER && brands.includes(vm.userBrand)
    ? [vm.userBrand]
    : brands;
  }
}
function extractValues(mappings) {
  const uniqueValues = new Set(Object.values(mappings));
  return [...uniqueValues];
}
function postMountColumnDefSetup(vm) {
  // // set up styling for RIO-11
  // for (const col of vm.columnDefs) {
  //   if (!col.cellClassRules) col.cellClassRules = {};
  //   col.cellClassRules.linked = row => row.data.parentId;
  //   if (col.hasOwnProperty('editable') && col.editable === true) {
  //     col.editable = row => !row.data.parentId;
  //   }
  // }
}

//For RIO-20
function shouldShowDropAction() {
  return localStorage.getItem('role') == 'Regional Merch'
      || localStorage.getItem('role') == 'Regional Lead'
      || localStorage.getItem('role') == 'Global Merch'
      || localStorage.getItem('role') == 'Global Merch Lead'
      || localStorage.getItem('role') == 'SuperUser'
}

function transformToDropPayload(rows) {
  const result = [];
  for (let row of rows) {
    const data = row.data;
    result.push({
      id: data.id,
      currentState: data.currentState,
      action: "DROP",
    });
  }
  return result;
}

/**
 * Called for dropping the request at any given point in time
 */
async function dropRequest(vm) {
  vm.loading = true;
  vm.gridApi.stopEditing();
  const rows = vm.gridApi.getSelectedNodes();

  // check at least a row is selected
  if (!rows.length) {
    vm.loading = false;
    swal.fire({ title: "Please Select a Request", type: "error" });
    return;
  }

  // role specific remap of row data for api
  const transformedPayload = transformToDropPayload(rows);

  const headers = getHeaders(vm.token);
  try {
    const response = await axios.post(ApiUris.requestapproval, transformedPayload, headers);
    var count = response.data.length;
    // eslint-disable-next-line
    vm.loading = false;
    clickStream.obEvent('Submit', `Submitted by ${vm.role}`)
    swal.fire({
      text: " Requests Dropped : " + count,
      type: "success",
      confirmButtonText: "Ok"
    });
    await fetchGridContents(vm);
    vm.gridApi.deselectAll();
  } catch(error) {
    await handleResponseError(vm, error, 'Error in fetching grid contents');
  }
}
async function autoPopulateValues(code, season, vm) {
  const headers = getHeaders(vm.token);
  try {
  if(code && season) {
  let url = `${ApiUris.lookupMap}${code}?season=${season}`
  const resp = await axios.get(url, headers);
  return resp.data;
  }
  }
  catch (e) {
    console.error(`Fetch PC5/PC9 Look up failed: ${e.message}`);
    return null;
  }
}
function lookupValue(mappings, key) {
  return mappings[key];
}
function findValue(field, key) {
    return lookupData[field][key];
}
function getRoleUrl(vm) {
  return vm.role === configData.roles.RM ? ApiUris.templateRM : ApiUris.templateGM
}
export default {
  CREATE_REQUEST,
  UPDATE_REQUEST,
  RETURN_REQUEST,
  ACTION_REQUEST,
  getHeaders,
  createUpdateReturnRequest,
  fetchGridContents,
  setFilters,
  hasMoreInfoReq,
  approveRequest,
  pushRoute,
  getPathByRole,
  assignToRoleDropdownValues,
  fetchLookupData,
  autosave,
  returnReportData,
  filterSubclasses,
  isEmptyStr,
  uploadImage,
  fetchImage,
  fireErrorModal,
  postMountColumnDefSetup,
  dateValueGetter,
  getBrandDropdownList,
  seasonComparator,
  shouldShowDropAction,
  dropRequest,
  uploadExcel,
  autoPopulateValues,
  lookupValue,
  findValue,
  extractValues,
  getRoleUrl
}