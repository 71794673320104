import parameters from "../config";

const NUMBER_PATTERN = /^\d+$/;

export default {

    /** @param {*} value can be either number or string */
    anticipatedVolume: value => validateRanges(value, 'volumeMinMax'),
    
    /** @param {*} value can be either number or string */
    sampleNeeds: value => validateRanges(value, 'sampleNeeds'),
    
    /** @param {*} value can be either number or string */
    leadTime: value => validateRanges(value, 'leadTime'),

    /** @param {*} value can be either number or string */
    protoNeeds: value => validateRanges(value, 'protoNeeds'),

    /** False if null, empty or undefined */
    generalTextValidation: value => !nullEmptyOrUndefined(value),
    
    countryValidation: (region, country) => region === 'All' || !nullEmptyOrUndefined(country),

    /** True if not empty, undefined, 'undefined', 'Invalid date' */
    dateValidation: value => {
        if (nullBlankOrUndefined(value)) return false;
        return !['undefined',"Invalid date"].includes(value)
    },

    pc9Validation: value => validatePatternsFromConfig(value, "pc9Validation"),
    actualPC9: value => validatePatternsFromConfig(value, "actualPC9"),
    fabricCode: value => validatePatternsFromConfig(value, "fabricCode"),
    targetCOGS: value => validatePatternsFromConfig(value, "targetCOGS"),
}

function validateRanges(value, configKey) {
    const [min, max] = parameters[configKey];
    return NUMBER_PATTERN.test(value) && value <= max && value >= min;
}

function validatePatternsFromConfig(value, configKey) {
    for (const pattern of parameters[configKey]) {
        if (new RegExp(pattern).test(value)) return true;
    }
    return false
}

function nullEmptyOrUndefined(value) { return value === "" || value === undefined || value === null }
function nullBlankOrUndefined(value) { return value === undefined || value === null || value.trim() === "" }