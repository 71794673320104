import moment from "moment";
import DatePicker from "../validators/datepicker";
import validations from "../fieldValidations";
import common from "../utils/common";
import ltv from "../validators/leadTimeValidator";
import configData from "../../config";
import AgGridThumbnail from '../components/AgGridThumbnail.vue'

async function beforeMount(vm) {
  vm.components.datePicker = DatePicker.getDatePicker();
  vm.defaultColDef.onCellValueChanged = function(params) {
    const field = params.colDef.field;
    const oldValue = params.oldValue || '';
    const newValue = params.newValue || '';
    console.debug(`(${params.data.id}) "${field}": ${oldValue} to ${newValue}; mappedValue=<${params.data[field]}>`);
    if (
      oldValue !== newValue &&
      field !== "approval_state" &&
      field !== "toState"
    ) {
      if (!params.node.isSelected()) {
        params.node.setSelected(true);
      }

      if (params.colDef.field === "leadTime") {
        var new_exfactory = moment(params.node.data.poIssuanceDate, "MM/DD/YYYY").add(
          params.newValue,
          "days"
        );

        var new_inDc = moment(new_exfactory, "MM/DD/YYYY").add(
          params.data.avgTimeDiff,
          "days"
        );
        params.node.setDataValue(
          "exFactoryDate",
          new_exfactory.format("MM/DD/YYYY")
        );
        params.node.setDataValue("inDCDate", new_inDc.format("MM/DD/YYYY"));
      } else if (params.colDef.field === "exFactoryDate") {
        var dcDate = moment(params.data.exFactoryDate, "MM/DD/YYYY").add(
          params.data.avgTimeDiff,
          "days"
        );
        params.node.setDataValue("inDCDate", dcDate.format("MM/DD/YYYY"));
      } else if (params.colDef.field === "poIssuanceDate") {
        var newpoi__exfDate = moment(
          params.data.poIssuanceDate,
          "MM/DD/YYYY"
        ).add(params.data.leadTime, "days");
        var newpoi_indc = moment(newpoi__exfDate, "MM/DD/YYYY").add(
          params.data.avgTimeDiff,
          "days"
        );
        params.node.setDataValue(
          "exFactoryDate",
          newpoi__exfDate.format("MM/DD/YYYY")
        );
        params.node.setDataValue(
          "inDCDate",
          newpoi_indc.format("MM/DD/YYYY")
        );
      }
    }
  };

  vm.rowClassRules = {
    recentRow: function(params) {
      var reqDate = new Date(params.data.modifiedDate);
      var today = new Date();
      today.setDate(today.getDate() - 5);
      if (reqDate > today) {
        return true;
      }
    }
  };
  
  const lookupTypes = configData.lookupTypes;
  await Promise.all([
    common.fetchLookupData(vm, [lookupTypes.gtmStages, lookupTypes.schedules])
  ]);
  
  vm.columnDefs = getColumnDefs(vm);
  common.postMountColumnDefSetup(vm);
}

function getColumnDefs(vm) {
  return [
    {
      headerName: "",
      field: "checkbox",
      pinned: "left",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 45,
      cellClassRules: {
        required: function(row) {
          if (row.node.isSelected()) {
            return !spValidation(row.data);
          }
        },
        recentRow: function(params) {
          var reqDate = new Date(params.data.modifiedDate);
          var today = new Date();
          today.setDate(today.getDate() - 5);
          if (reqDate > today) {
            return true;
          }
        }
      }
    },
    {
      headerName: "Approve/Reject",
      field: "approval_state",
      editable: true,
      suppressPaste: true,
      cellEditor: "agRichSelectCellEditor",
      pinned: "left",
      width: 145,
      cellEditorParams: {
        values: ["Approve", "Reject", "Drop", "MORE_INFO"]
      }
    },
    {
      headerName: "ID",
      field: "id",
      pinned: "left",
      filter: true,
      width: 120,
      cellClass: "noneditableCol",
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      }
    },
    // {
    //   headerName: "Parent ID",
    //   field: "parentId",
    //   width: 115,
    //   cellClass: "noneditableCol",
    //   filter: true,
    //   pinned: "left",
    // },
    {
      headerName: "Status",
      field: "lastAction",
      width:150,
      filter: true,
      pinned: "left",
      cellClass: "noneditableCol",
      valueGetter: function(params) {
        if (params.data.currentState == 5) {
          return "New";
        } else if (params.data.currentState == 6) {
          return "APPROVED";
        }
      }
    },
    {
      headerName: "Thumbnail",
      field: 'productUrl',
      pinned: "left",
      width:130,
      filter: false,
      cellRendererFramework: AgGridThumbnail,
    },
    {
      headerName: "Curr Stage",
      field: "currentState",
      cellClass: "noneditableCol",
      filter: true,
      width: 130,
      pinned: "left",
      hide: true,
    },
    {
      headerName: "Assign To Role",
      field: "toState",
      editable: row => row.data.approval_state === "MORE_INFO",
      width:140,
      cellEditor: "agRichSelectCellEditor",
      cellClassRules: {
      noneditableCol: row => !(row.data.approval_state === "MORE_INFO"),
      required: row => row.node.isSelected() && row.data.approval_state === "MORE_INFO" && !validations.generalTextValidation(row.data.toState),
        },
      cellEditorParams: function() {
        return common.assignToRoleDropdownValues();
      },
    },
    {
      headerName: "More Info Comments",
      field: "moreInfoComments",
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 1000,
     },
      editable: row => (row.data.lastAction === "MORE_INFO" || row.data.approval_state === "MORE_INFO"),
      cellClassRules: {
      noneditableCol: row => !(row.data.lastAction === "MORE_INFO" || row.data.approval_state === "MORE_INFO")
      },
    },
    {
      headerName: "Confirmation Date",
      editable: true,
      cellEditor: "datePicker",
      field: "confirmationDate",
      cellClassRules: {
        required: function(params) {
          if (
            params.node.isSelected() &&
            params.data.requestType !== "Carryover" &&
            params.data.approval_state == "Approve"
          ) {
            var value = !validations.dateValidation(params.value);
            return value;
          }
        }
      }
    },
    {
      headerName: "Season",
      field: "season",
      width: 120,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: vm.seasonCodes },
      filterParams: { comparator: common.seasonComparator },
    },
    {
      headerName: "GTM Stage",
      field: "gtmStage",
      width: 145,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData[configData.lookupTypes.gtmStages])
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
      },
      suppressPaste: true
    },
    {
      headerName: "Brand",
      field: "brand",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Clusters",
      field: "requestingRegion",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Channel",
      field: "channel",
      cellClass: "noneditableCol",
      filter: true
    },
    {
      headerName: "Biggest Volume Country",
      field: "country",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Anticipated Volume",
      field: "anticipatedVolume",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Last Season Offered",
      field: "lastSeasonOffered",
      cellClass: "noneditableCol",
      filter:true
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Category",
      field: "category",
      cellClass: "noneditableCol",
      filter:true
    },
    {
      headerName: "Class/SubClass",
      field: "classSubClass",
      cellClass: "noneditableCol",
      filter:true
    },
    {
      headerName: "PC9",
      field: "actualPC9",
      cellClass: "noneditableCol",
      filter:true
    },
    {
      headerName: "Price Tier",
      field: "priceTier",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Type of Request",
      field: "requestType",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "Reason Code",
      field: "requestReason",
      filter: true,
      cellClass: "noneditableCol"
    },
    // {
    //   headerName: "Pull Forward (Y/N)",
    //   field: "pullForward",
    //   cellClass: "noneditableCol",
    //   filter: true
    // },
    {
      headerName: "Global Merchant",
      field: "globalMerchant",
      filter: true,
      cellClass: "noneditableCol"
    },
    {
      headerName: "PD Contact",
      field: "pdContact",
      cellClass: "noneditableCol"
    },
    {
      headerName: "Vendor ID",
      field: "vendorId",
      cellClass: "noneditableCol",
    },
    {
      headerName: "Finish Development Track",
      field: "finishDevelopmentTrack",
      cellClass: "noneditableCol",
    },
    {
      headerName: "SP Contact",
      field: "spContact",
      editable: true,
      cellClassRules: {
        required: function(params) {
          if (
            params.node.isSelected() &&
            params.data.requestType == "New" &&
            params.data.approval_state == "Approve"
          ) {
            var value = !validations.generalTextValidation(params.value);
            return value;
          }
        }
      }
    },
    {
      headerName: "Request On Floor (MM/DD/YYYY)",
      field: "requestOnFloor",
      cellClass: "noneditableCol",
      valueFormatter: function(params) {
        return moment(params.data["requestOnFloor"]).format("MM/DD/YYYY");
      }
    },
    {
      headerName: "MRP Date (MM/DD/YYYY)",
      field: "pdmrpDate",
      cellClass: "noneditableCol",
      valueFormatter: function(params) {
        return moment(params.data["pdmrpDate"]).format("MM/DD/YYYY");
      }
    },
    {
      headerName: "Lead Time",
      field: "leadTime",
      editable: true,
      cellEditor: ltv,
      cellStyle:{overflow:"visible !important"},
      cellClassRules: {
        required: function(params) {
          if (
            params.node.isSelected() &&
            params.data.requestType == "New" &&
            params.data.approval_state == "Approve"
          ) {
            var value = !validations.leadTime(params.value);
            return value;
          }
        }
      }
    },
    {
      headerName: "PO Issuance Date",
      field: "poIssuanceDate",
      editable: true,
      cellEditor: "datePicker",

      cellClassRules: {
        required: function(params) {
          if (
            params.node.isSelected() &&
            params.data.requestType !== "Carryover" &&
            params.data.approval_state == "Approve"
          ) {
            var value = !validations.dateValidation(params.value);
            return value;
          }
        }
      }
    },
    {
      headerName: "Ex-Factory Date",
      field: "exFactoryDate",
      editable: true,
      cellEditor: "datePicker",
      cellClassRules: {
        required: function(params) {
          if (
            params.node.isSelected() &&
            params.data.requestType !== "Carryover" &&
            params.data.approval_state == "Approve"
          ) {
            var value = !validations.dateValidation(params.value);
            return value;
          }
        }
      }
    },
    {
      headerName: "In DC Date",
      field: "inDCDate",
      editable: true,
      cellEditor: "datePicker",
      cellClassRules: {
        required: function(params) {
          if (
            params.node.isSelected() &&
            params.data.requestType !== "Carryover" &&
            params.data.approval_state == "Approve"
          ) {
            var value = !validations.dateValidation(params.value);
            return value;
          }
        }
      }
    },
    {
      headerName: "SP Comments",
      field: "spComments",
      editable: true
    }
  ];
}

function updated() {}

function save(vm) {
  common.approveRequest(vm, validation, transformToPayload);
}

function drop(vm) {}

function validation(rows) {
  for (let row of rows) {
    if (!spValidation(row.data)) return false;
  }
  return true;
}

/**
 * 
 * @param {*} data 
 * @returns {boolean} true if succeeds
 */
function spValidation(data) {
  // RIO-80 - fields are all optional for Supply Planning for Carryover requests
  if (data.requestType === 'Carryover')
    return true;
  
  if (data.approval_state === "Approve" && data.requestType === "New") {
    if (!validations.dateValidation(data.exFactoryDate)
      || !validations.dateValidation(data.poIssuanceDate)
      || !validations.dateValidation(data.confirmationDate)
      || !validations.leadTime(data.leadTime)
      || !validations.generalTextValidation(data.spContact)
      || !validations.generalTextValidation(data.inDCDate)) {
      return false;
    }
  } else if (data.approval_state === "MORE_INFO" && !validations.generalTextValidation(data.toState)) {
    return false;
  }

  return true;
}

function transformToPayload(rows) {
  const result = [];
  for (let row of rows) {
    const data = row.data;
    result.push({
      id: data.id,
      currentState: data.currentState,
      comments: data.spComments,
      spComments: data.spComments,
      poIssuanceDate: data.poIssuanceDate,
      exFactoryDate: data.exFactoryDate,
      action: data.action,
      pdContact: data.pdContact,
      gdContact: data.gdContact,
      faCode: data.faCode,
      fabricDescription: data.fabricDescription,
      vendorId: data.vendorId,
      pdMRPDate: data.pdMRPDate,
      pdApprove: data.pdApprove,
      inDCDate: data.inDCDate,
      confirmationDate: data.confirmationDate,
      moreInfoComments: data.moreInfoComments,
      requestReason: data.requestReason,
    });
  }
  return result;
}

export default {
  beforeMount,
  updated,
  save,
  drop,
  getColumnDefs,
}