//main.js

import Vue from 'vue';
import App from './App.vue';
import Purecss from 'purecss';
import VueRouter from 'vue-router';
import routes from './routes';
import axios from 'axios';
import VTooltip from 'v-tooltip';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import params from '../config'
import { init as initFullStory, FullStory } from '@fullstory/browser'

Vue.config.productionTip = false

initFullStory({ orgId: process.env.VUE_APP_FULLSTORY_ORGID })
Vue.use(FullStory);
Vue.use(VueRouter);
Vue.use(VTooltip);
Vue.use(VuejsDialog);

const router = new VueRouter({ mode: 'history', routes });

//License details of Ag-grid
var licenceKey = params['agGrid']['licenceKey']
LicenseManager.setLicenseKey(licenceKey);


router.beforeEach((to, from, next) => {
    localStorage.setItem('route', '');
    // debugger;

    // helpful for development environment:
    // add VUE_APP_TURN_OFF_LOGIN_REDIRECT=true to your .env.local file to turn off user role redirects
    const turnOffLoginRedirect = (process.env.VUE_APP_TURN_OFF_LOGIN_REDIRECT)
        ? process.env.VUE_APP_TURN_OFF_LOGIN_REDIRECT.toLowerCase() === "true"
        : false;
    
    if (turnOffLoginRedirect) {
        nextWrapper('', 'turnOffLoginRedirect'); return;
    }

    // set of possible conditions to redirect to login page
    if (to.fullPath.startsWith('/requests')) {
        localStorage.setItem('route', to.fullPath.split('/').pop());
        
        const userAuthenticated = localStorage.getItem('userAuthenticated') === 'true';
        if (!userAuthenticated) {
            nextWrapper('/auth-callback', 'userAuthenticated==false'); return;
        }
        
        // to keep legacy behavior: Global Merchant is trying to create new request
        // so we'll redirect to Regional Merch screen
        const gm2RmKey = 'globalMerchToRegionalMerchScreen';
        if (localStorage.getItem(gm2RmKey) === 'true') {
            localStorage.setItem(gm2RmKey, false);
            nextWrapper('', 'Redirect Global Merch to Regional Merch'); return;
        }
        
        const currentRole = localStorage.getItem('role');
        if (currentRole === 'SuperUser') {
            nextWrapper('', 'SuperUser role'); return;
        }
        
        if (to.meta.requiresRole && (currentRole !== to.meta.roleType || !to.meta.roleType.includes(currentRole))) {
            nextWrapper('/auth-callback', 'Page requires roleType currentRole is not'); return;
        }

    } else if (!(to.fullPath.startsWith('/unauthorized') || to.fullPath.startsWith('/logout') || to.fullPath.startsWith('/auth-callback'))) {
        nextWrapper('/auth-callback', 'path is neither unauthorized/logout/auth-callback so redirect to login'); return;
    }
    FullStory('trackEvent', {
        name: 'GapUserRole',
        properties: {
          GapRole: localStorage.getItem('role')
        }
      })
    FullStory('trackEvent', {
        name: 'GapCluster',
        properties: {
          Cluster: localStorage.getItem('region')
    }
    })

    nextWrapper('', 'default'); return;


    function nextWrapper(path/*, details*/) {
        path = path || '';
        // console.debug(`next(${path})` + (details ? `, ${details}` : ''));
        if (path) next(path);
        else next();
    }
});

new Vue({
    router,
    render: h => h(App, Purecss, VueRouter, axios, VTooltip, VuejsDialog)
}).$mount('#app')