<template>
  <div>
  <div class="pure-menu-horizontal navbar">
    <img class="nav-img" src="../assets/logo.jpg">  
    <label class="navTitle">LEVI STRAUSS & CO.</label>
    <ul class="pure-menu-list">
      
        <li @click.capture='myrequest' class="pure-menu-item">
          <span v-on:click="listrequests()" v-if="actualRole!==`View Only`" class="pure-menu-link">My Requests</span>
        </li>
        
        
        <li class="pure-menu-item">
          <span v-on:click="addNewReq()" v-if="role===`Global Merch`" class="pure-menu-link">Add New Request</span>
        </li> 
        <li @click.capture='summary' class="pure-menu-item">
          <router-link  to='/requests/summary' class="pure-menu-link">Summary</router-link>
        </li>
        <li @click.capture='report' class="pure-menu-item">
          <router-link  to='/requests/report' class="pure-menu-link">Report</router-link>
        </li>
    </ul>
    <li data-v-d4e0137a="" class="pure-menu-link" v-on:click="logout" style="float: right;margin-right: 3vw;">
        Logout
    </li>
  </div>
  <div>
    <router-view />
  </div>
  </div>

</template>

<script>

import { clearSession } from "../../utils/helpers";
import common from '../utils/common';
import clickStream from '../utils/clickStream';

export default {
  name: 'NavigationMenu',
  props: {
    msg:String,
  },
  data(){
    return {
      role: this.$router.currentRoute.meta.roleType,
      actualRole: localStorage.getItem('role'),
    }
  },
  methods : {
    logout : function(){
      this.$dialog.confirm('Are you sure you want to logout ?',{okText: 'Yes',
              cancelText: 'No',
              backdropClose: false})
              .then(() => { 
                  clearSession()
                  common.pushRoute(this.$router, "/logout");
                })
                .catch(() => {
                  //Do nothing
                });
    },
    summary(){
       clickStream.obEvent('Summary', `Selected Summary`)
    },
    report(){
       clickStream.obEvent('Report', `Selected Report`)
    },
    myrequest(){
       clickStream.obEvent('Request', `My Requests`)
    },
    addNewReq:function(){
      localStorage.setItem('globalMerchToRegionalMerchScreen', true);
      common.pushRoute(this.$router, "/requests/list");
    },

    listrequests : function(){
      const urlPath = common.getPathByRole(localStorage.getItem('role'));
      common.pushRoute(this.$router, urlPath);
    },
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .nav-img{
    height: 40px;
    margin-left: 2vw;
    margin-top: -3px;
    float: left;
  }

  .navbar{
    height: 60px;
    border: 2px solid darkgray;
    background-color: black;
    margin-top:20px;
    vertical-align:middle;
    padding-top:15px;
  }

  .navTitle{
    font-size: 20px;
    color:white;
    margin-left:15px;
  }

  .pure-menu-horizontal {
    margin-left: 20px;
    margin-top: 10px;
  }

  .pure-menu-link{
    color:white;
  }

  
  .pure-menu-link:hover{
    background:none;
    text-decoration:none;
  }

  .pure-menu-link:active{
    background:none;
  }

  .pure-menu-link:focus{
    background:none;
  }

  .pure-menu-link:after {
    font-size: x-large;
  }

  .pure-menu-list{
    margin-left: 10vw;
  }

  .pure-menu-item{
    margin-left:2vw;
    margin-right:2vw;
  }

  .router-link-active {
    border-bottom: 2px solid darkgray;
  }

  .pure-menu-link:hover {
    border-bottom: 2px solid darkgray;
  }

</style>
