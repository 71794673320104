export default {

    /**
     * The parameters are passed in only by requestOnFloor column by Regional Merch (GB-165)
     * @param {*} seasons list of seasons from graph, including gtm stages and optional earliest startRequestOnFloor dates
     * @param {*} configData 
     * @returns 
     */
    getDatePicker: function getDatePicker(seasons, configData) {

        function Datepicker() { }

        // triggers onclick of date input (formats what dates user can pick?)
        Datepicker.prototype.init = function (params) {
            this.eInput = document.createElement("input");
            this.eInput.value = params.value || '';

            const datePickerObj = { dateFormat: 'mm/dd/yy' };

            // GB-165 set an earliest Request On Floor date to datePickerObj
            if (params.colDef && params.colDef.field === 'requestOnFloor') {
                const rowSeason = params.data.season;
                // hlin: I think row gtm is set based off of seasons from graph
                // either 'SRT #2' or 'SRT #1'
                const rowGtm = params.data.gtmStage;
                const rowrequestType = params.data.requestType;
                
                // set a default based on season. H1 = 02/01, H2 = 07/01
                let earliest;
                
                // use season & gtm stage specific earliest dates from the graph
                if (seasons) {
                    const isOffCalendar = rowGtm === configData.datafields.default_gtm_stage;
                    for (let sobj of seasons) {
                        const [season, gtm] = sobj.season_gtm;
                        if (season !== rowSeason) continue;
                        if (isOffCalendar || gtm === rowGtm) {
                            // if off calendar, just use the first one
                            earliest = sobj.startRequestOnFloor;
                            if (earliest) console.debug(`Using earliest from ${season}_${gtm} = "${earliest}"`);
                            else console.debug(`Earliest not found in graph for ${season}_${gtm}`);
                            break;
                        }
                    }
                    console.debug('isOffCalendar', isOffCalendar);
                }

                if (!earliest) {
                    earliest = getDefaultEarliestRequestOnFloorDate(rowSeason, rowGtm, rowrequestType);
                    console.debug(`Using default earliest for ${rowSeason} = ${earliest}`);
                }
                
                if (earliest)
                    datePickerObj.minDate = new Date(earliest);
                else
                    console.debug('Defaulting to no set ranges');
            }
            if (params.colDef && params.colDef.field === 'inDCDate') {
                const rowGtm = params.data.gtmStage;
                const inDCDateValue = params.data.inDCDate;
                const rowrequestType = params.data.requestType;
                const date = new Date();
                date.setDate(date.getDate() + 2);
                const currentYear = date.getFullYear();
                const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
                const currentDate = String(date.getDate()).padStart(2, '0');
                if (rowrequestType === 'New' && (rowGtm === 'SRT #1' || rowGtm === 'SRT #2')) {
                    let earliest = `${currentYear}-${currentMonth}-${currentDate}`;
                    datePickerObj.maxDate = new Date(inDCDateValue);
                    datePickerObj.minDate = new Date(earliest);
                } else {
                    let earliest = `${currentYear}-${currentMonth}-${currentDate}`;
                    datePickerObj.minDate = new Date(earliest);
                }
            }

            // disable in eslint bc it's not recognizing jquery, not sure where it's imported from
            // eslint-disable-next-line
            $(this.eInput).datepicker(datePickerObj);
        };
        
        Datepicker.prototype.getGui = function () {
            return this.eInput;
        };
        
        Datepicker.prototype.afterGuiAttached = function () {
            this.eInput.select();
        };
        
        Datepicker.prototype.getValue = function () {
            // notes: triggers on exit (mouse click or Enter key) of this cell
            return this.eInput.value;
        };
        
        Datepicker.prototype.destroy = function () { };

        return Datepicker;
    }
}

export function getDefaultEarliestRequestOnFloorDate(season, gtmStage, rowrequestType) {
    try {
        const [sh, sy] = season.trim().toUpperCase().split("'");
        let year = 2000 + parseInt(sy);
        const date = new Date();
        date.setDate(date.getDate() + 2);
        const currentYear = date.getFullYear();
        const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        const currentDate = String(date.getDate()).padStart(2, '0');

        if (isNaN(year)) {
            console.error(`Error parsing year from season "${season}"...`);
            return '';
        }
        let month;
        let startDate = '00';
        if(rowrequestType === 'New') {
        if (sh === 'H1') {
            if(gtmStage === 'SRT #1') {
                month = '01';
            } else if (gtmStage === 'SRT #2') {
                month = '02';
            } else {
                month = currentMonth;
                startDate = currentDate
                year = currentYear
            }
        } else if (sh === 'H2') {
            if(gtmStage === 'SRT #1') {
                month = '07';
            } else if (gtmStage === 'SRT #2') {
                month = '08';
            } else {
                month = currentMonth;
                startDate = currentDate
                year = currentYear
            }
        } else {
            console.error(`Error parsing which half from season "${season}"...`);
            return '';
        }
    } else {
        month = currentMonth;
        startDate = currentDate
        year = currentYear
    }

        return `${year}-${month}-${startDate}`;
    } catch (e) { 
        console.error(`Error parsing season column: "${season}"...`); 
        return '';
    }
}