import moment from 'moment';
import swal from "sweetalert2";


export function base64URLEncode(str) {
    return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export function isAuthenticated(token) {
    return (!token || !token.exp) ? false : !isTokenExpired(token);
}

export function isTokenExpired(token) {
    let tokenDate = new Date(0); // 0 sets date to epoch
    tokenDate.setUTCSeconds(token.exp);
    let now = new Date();
    console.log(tokenDate)
    return tokenDate <= now;
}

export function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    var js = JSON.parse(jsonPayload);

    return js.sub.split('@')[0]
}

export function axiosRequestConfig(creds) {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${creds}`
        }
    }
}

export const session = {
    VERIFIER_KEY: 'codeVerifier',
    CHALLENGE_KEY: 'codeChallenge',
    REDIRECT_URI: 'redirectUri'
}

export var sessionTimer =
    setInterval(() => {
        var expiry_time = localStorage.getItem("tokenexpiryTime")


        if ((expiry_time - moment().unix()) < 600000) {
            swal.fire({
                text: "Your Session is about to expire. Please Save data",
                type: "info",
                confirmButtonText: "Ok"
            })
        }

    }, 600000);


export function clearSession() {
    clearInterval(sessionTimer)
}