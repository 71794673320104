<template>
  <div style="text-align:center;margin:15%">
            <div>
                <p>
                   You are logged out of the application.
                </p>
                <router-link to="/auth-callback" class="item">
                  <i class="user icon"></i> Log back in
                </router-link>
            </div>
        </div>
</template>
<script>
import { session } from "../../utils/helpers";
import conf from "../../utils/applicationConfiguration";

export default {
  name: 'Logout-Grid',
  beforeMount() {
    if(localStorage.getItem("userAuthenticated")){
      localStorage.removeItem("userAuthenticated");
      localStorage.removeItem("jwtToken");
      const params = new URLSearchParams();
      params.set("post_logout_redirect_uri", localStorage.getItem(session.REDIRECT_URI));
      params.set("id_token_hint", localStorage.getItem("idToken"));
      this.authLogout = `${conf.authentication.logoutUrl}?${params.toString()}`;
      this.redirect = true;
      localStorage.setItem("loggingOut",true);
      window.location.href = this.authLogout;
    } else {
      localStorage.removeItem("loggingOut");
    }
  }
}
</script>
