<template>
  <div id="app">
    <!-- adding key binding here to reload component when switching between My Requests and Summary -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  vertical-align:middle;
  margin-top: 10px;
}
.borderRequired{
  background-color: red
}



.borderNone{
  background-color: black
}
 @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
</style>
