import validations from "../../config";

function ValidationCellEditor() {}

ValidationCellEditor.prototype.init = function(params) {
    this.isValid = true;
    this.isValidating = false;

    this.validFormats = validations["actualPC9"]

    this.eGui = document.createElement('div');
    const value = [undefined, '', null].includes(params.value) ? '""' : params.value;
    this.eGui.innerHTML = `
        <input value=${value}  />
        <span class="validating-msg hide"></span>
        <span class="validating-result hide"><span>
    `;
    this.eInput = this.eGui.querySelector('input');
    this.eValidating = this.eGui.querySelector('.validating-msg');
    this.eResult = this.eGui.querySelector('.validating-result');
    this.eInput.addEventListener('input', this.inputChanged.bind(this));
    this.eInput.addEventListener('keydown', this.keydownEvent.bind(this));
    // this.eInput.addEventListener('blur', this.blurEvent.bind(this));
}

ValidationCellEditor.prototype.inputChanged = function(event) {
    this.isValid = false
    this.validFormats.forEach(element => {
        var reg = new RegExp(element)
        if (reg.test(event.target.value)) {
            this.isValid = true
        }
    })

    if (this.isValid) {
        this.eValidating.classList.add('hide');
        this.eResult.classList.remove('hide');
        this.eResult.innerHTML = validations.TICK_SYMBOL;
    } else {
        this.eValidating.classList.remove('hide');
        this.eValidating.textContent = "Allowable Formats : XXXXX-9999"
        this.eResult.classList.remove('hide');
        this.eResult.innerHTML = validations.CROSS_SYMBOL;
    }
}

ValidationCellEditor.prototype.keydownEvent = function(event) {
    if (event.keyCode == 9 && !this.isValid) {
        event.stopImmediatePropagation();
    }
}

// focus and select can be done after the gui is attached
ValidationCellEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// ValidationCellEditor.prototype.blurEvent = function() {
//     this.eInput.focus();
//     this.eInput.select();
// }

ValidationCellEditor.prototype.isCancelAfterEnd = function() {
    return !this.isValid || this.isValidating;
}

ValidationCellEditor.prototype.getValue = function() {
    return this.eInput.value;
}

ValidationCellEditor.prototype.getGui = function() {
    return this.eGui;
}

ValidationCellEditor.prototype.destroy = function() {
    this.eInput.removeEventListener('input', this.inputChanged);
}

export default ValidationCellEditor;