<template>
<div>
    <div  v-if='params.value'  @click='openModal' style="cursor:pointer;">
       <img class='imageStyle' :src="imgSrc"/>
           </div>
        <div v-else-if='role=="list"' >
            <div  @dragover.prevent @drop.prevent @drop="fileUploaded" v-if='url'>
              <img style="cursor:pointer;" @click='openModal' class='imageStyle' :src="url" />
            </div>
        <div v-else >
      <label @dragover.prevent @drop.prevent @drop="fileUploaded" class="filelabel">
    <span class="title">
       Upload Image
    </span>
    <input @change='fileUploaded' class="FileUpload1" id="FileInput" name="booking_attachment" type="file"/>
</label>
</div>
       </div>
       <div v-else>
           <p class="thumbnailNA">Image Not Available </p>
        </div>
</div>
</template>

<script>
import Vue from 'vue'
import swal from "sweetalert2";
import common from '../utils/common'
export default Vue.extend({ 
  data(){
    return {
        role: localStorage.getItem('route'),
        url:'',
        imgSrc: ''
    }
  },
  async mounted(){
    if(this.params.value) {
    await this.fetchImage()
    }
  },
  computed:{},
  methods:{
    openModal() {   
      swal.fire({
        title: this.params.data.id,
        imageUrl: this.url || this.imgSrc,
        imageAlt: 'Thumbnail.jpg',
        width: 600,
      })
    },
    async fetchImage() {
      let src = `${process.env.VUE_APP_API_URL}/productimages/${this.params.value}`
      let img =   await common.fetchImage(src)
        this.imgSrc =  img
    },
    async fileUploaded(e) {
      let file
      if(e.target.files){
        file = e.target.files[0]
      } else {
        file = e.dataTransfer.files[0]
      }
      if(file.type === "image/png" || file.type === "image/jpeg") {
        var data = new FormData()
        data.append('file', file)
        data.append('id', this.params.data.id)
        this.url = URL.createObjectURL(file);
        await common.uploadImage(data, this)
      } else {
        swal.fire({
          title: "Please upload Png or Jpeg type",
          type: "error",
          confirmButtonText: "Ok"
        });
      }
    }
  }
})
</script>
<style scoped>
.filelabel {
    width: 110px;
    border: 2px dashed grey;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 10px;
    height: 25px;
    padding-bottom: 2px;
}
.filelabel .title {
  color: grey;
  transition: 200ms color;
}
.filelabel:hover {
  border: 2px solid #1665c4;
}
.filelabel:hover i,
.filelabel:hover .title {
  color: #1665c4;
}
#FileInput{
    display:none;
}
.imageStyle{
    margin: auto;
    display: block;
    width: auto;
    height: 20px;
    margin-top: 3px;
}
.thumbnailNA{
  font-size: 10px;
}
.preview{
    height: 20px;
}
</style>