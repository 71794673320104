<template>
<div style="marginLeft:10vw;marginTop:5vh" class="">
<div id="loading" v-if="loading"></div>
<div style="display:flex;">
<div style="fontSize:15px;">
      <label for="season">Season:</label>
  <select style="marginLeft:10px" v-model='seasonTrack' name="season" id="season">
    <option v-for='season in seasons' :key='season' :value="season">{{season}}</option>
  </select>
</div>
<div style="fontSize:15px; marginLeft:20px"> 
       <label for="brand">Brand:</label>
  <select style="marginLeft:10px" v-model='brandTrack' name="brand" id="brand">
    <option v-for='brand in brands' :key='brand' :value="brand">{{brand}}</option>
  </select>

</div>
<div style="fontSize:15px; marginLeft:20px"> 
       <label for="role">Submitted/Approved By:</label>
  <select style="marginLeft:10px" v-model='roleTrack' name="role" id="role">
       <option value="Regional-Merch">Regional Merch</option>
       <option value="Global-Merch-Lead">Global Merch Lead</option>
  </select>

</div>
</div>

<div class="reportTable">
<table style=" width:80%;">
    <thead>
  <tr>
    <th rowspan="2">Clusters</th>
    <th colspan="2">SRT #2</th>
    <th colspan="2">SRT #1</th>
    <th colspan="2" v-show='!hideCol()'>Off Calendar</th>
    <th colspan="2" v-show='!hideCol()'>Chase</th>
    <th colspan="2" v-show='hideCol()'>Trend Chase</th>
    <th colspan="2" v-show='hideCol()'>Pull Forward</th>
    <th colspan="2" v-show='hideCol()'>Clubs</th>
    <th colspan="2" v-show='hideCol()'>Excess Inventory</th>
    <th colspan="2" v-show='hideCol()'>Graphic Agility</th>
  </tr>
  <tr class="rowWidth">
      <th>New</th>
      <th>Carryover</th>
      <th>New</th>
      <th>Carryover</th>
      <th v-show='!hideCol()'>New</th>
      <th v-show='!hideCol()'>Carryover</th>
      <th v-show='!hideCol()'>New</th>
      <th v-show='!hideCol()'>Carryover</th>
      <th v-show='hideCol()'>New</th>
      <th v-show='hideCol()'>Carryover</th>
      <th v-show='hideCol()'>New</th>
      <th v-show='hideCol()'>Carryover</th>
      <th v-show='hideCol()'>New</th>
      <th v-show='hideCol()'>Carryover</th>
      <th v-show='hideCol()'>New</th>
      <th v-show='hideCol()'>Carryover</th>
      <th v-show='hideCol()'>New</th>
      <th v-show='hideCol()'>Carryover</th>
    </tr>
       </thead>
       <tbody v-if='tableContent.length'>
            <tr v-for='data in tableContent' :key='data.cluster'>
            <td >{{data.cluster}}</td>
            <td>{{getField('SRT2N', data.cluster)}}</td>
            <td>{{getField('SRT2Co', data.cluster)}}</td>
            <td>{{getField('SRT1N', data.cluster)}}</td>
            <td>{{getField('SRT1Co', data.cluster)}}</td>
            <td v-show='!hideCol()'>{{getField('OFFN', data.cluster)}}</td>
            <td v-show='!hideCol()'>{{getField('OFFCo', data.cluster)}}</td>
            <td v-show='!hideCol()'>{{getField('ChaseN', data.cluster)}}</td>
            <td v-show='!hideCol()'>{{getField('ChaseCo', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('TrendN', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('TrendCo', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('PulFrdN', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('PulFrdCo', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('ClubsN', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('ClubsCo', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('ExcInN', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('ExcInCo', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('GrphN', data.cluster)}}</td>
            <td v-show='hideCol()'>{{getField('GrphCo', data.cluster)}}</td>
        </tr>
        </tbody>
        <tbody  v-else>
          <tr>
            <td colspan="8" style="text-align:center;border:0;">
              No Rows To Display
            </td>
          </tr>
        </tbody>
</table>
</div>
</div>
</template>
<script>

import common from "../utils/common";
import configData from "../../config";

export default {
  data() {
      return {
          loading: false,
          tableContent:[],
          token: localStorage.getItem("idToken"),
          seasons:[],
          seasonTrack:"H2'21",
          brands:[],
          brandTrack:"Levis",
          roleTrack:'Regional-Merch',
      }
   },
   watch:{
       seasonTrack(newVal, oldVal) {
           if(newVal !== oldVal) {
               this.refetchData()
           }
       },
       brandTrack(newVal, oldVal) {
           if(newVal !== oldVal) {
               this.refetchData()
           }
       },
       roleTrack(newVal, oldVal) {
           if(newVal !== oldVal) {
               this.refetchData()
           }
       }
   },
   async mounted(){
       const lookupTypes = configData.lookupTypes
        this.tableContent = await common.returnReportData(this);
        await common.fetchLookupData(this,[lookupTypes.schedules])
        this.seasons = this.seasonCodes
        this.brands = configData.datafields["brand"]
    },
  methods:{
      async refetchData() {
           this.tableContent = await common.returnReportData(this);
      },
      getField(val, cluster) {
          let returnData = '-'
          if(val === 'SRT2N') {
               this.tableContent.map(info =>{
                   if(info.cluster === cluster )
                   info.data.map(data =>{
                      if(data.gtmStage === "SRT #2" && data.requestType === "New") {
                         returnData =  data.count
                      }
                  })
              })
          } else if(val === 'SRT2Co') {
               this.tableContent.map(info =>{
                   if(info.cluster === cluster )
                   info.data.map(data =>{
                      if(data.gtmStage === "SRT #2" && data.requestType === "Carryover") {
                         returnData =  data.count
                      }
                  })
              })
          } else if(val === 'SRT1N') {
               this.tableContent.map(info =>{
                   if(info.cluster === cluster )
                   info.data.map(data =>{
                      if(data.gtmStage === "SRT #1" && data.requestType === "New") {
                         returnData =  data.count
                      }
                  })
              })
          } else if(val === 'SRT1Co') {
               this.tableContent.map(info =>{
                   if(info.cluster === cluster )
                   info.data.map(data =>{
                      if(data.gtmStage === "SRT #1" && data.requestType === "Carryover") {
                         returnData =  data.count
                      }
                  })
              })
          } else if(val === 'OFFN') {
               this.tableContent.map(info =>{
                   if(info.cluster === cluster )
                   info.data.map(data =>{
                      if(data.gtmStage === "Off Calendar" && data.requestType === "New") {
                         returnData =  data.count
                      }
                  })
              })
          } else if(val === 'OFFCo') {
               this.tableContent.map(info =>{
                   if(info.cluster === cluster )
                   info.data.map(data =>{
                      if(data.gtmStage === "Off Calendar" && data.requestType === "Carryover") {
                         returnData =  data.count
                      }
                  })
              })
          } else if(val === 'ChaseN') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                info.data.map(data =>{
                  if(data.gtmStage === "Chase" && data.requestType === "New") {
                    returnData =  data.count
                  }
                })
            })
          } else if(val === 'ChaseCo') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                info.data.map(data =>{
                  if(data.gtmStage === "Chase" && data.requestType === "Carryover") {
                    returnData =  data.count
                  }
                })
            })
          } else if(val === 'TrendN') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                 info.data.map(data =>{
                   if(data.gtmStage === "Trend Chase" && data.requestType === "New") {
                     returnData =  data.count
                   }
                 })
            })
          } else if(val === 'TrendCo') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                 info.data.map(data =>{
                   if(data.gtmStage === "Trend Chase" && data.requestType === "Carryover") {
                      returnData =  data.count
                   }
                 })
            })
          } else  if(val === 'PulFrdN') {
             this.tableContent.map(info =>{
               if(info.cluster === cluster )
                 info.data.map(data =>{
                    if(data.gtmStage === "Pull Forward" && data.requestType === "New") {
                       returnData =  data.count
                    }
                 })
             })
          } else  if(val === 'PulFrdCo') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                info.data.map(data =>{
                  if(data.gtmStage === "Pull Forward" && data.requestType === "Carryover") {
                     returnData =  data.count
                  }
                })
            })
          } else  if(val === 'ClubsN') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                info.data.map(data =>{
                  if(data.gtmStage === "Clubs" && data.requestType === "New") {
                    returnData =  data.count
                  }
                })
            })
          } else  if(val === 'ClubsCo') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                info.data.map(data =>{
                  if(data.gtmStage === "Clubs" && data.requestType === "Carryover") {
                    returnData =  data.count
                  }
                })
            })
          } else  if(val === 'ExcInN') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                 info.data.map(data =>{
                   if(data.gtmStage === "Excess Inventory" && data.requestType === "New") {
                      returnData =  data.count
                   }
                 })
            })
          } else  if(val === 'ExcInCo') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                 info.data.map(data =>{
                   if(data.gtmStage === "Excess Inventory" && data.requestType === "Carryover") {
                     returnData =  data.count
                   }
                 })
            })
          } else  if(val === 'GrphN') {
            this.tableContent.map(info =>{
              if(info.cluster === cluster )
                 info.data.map(data =>{
                   if(data.gtmStage === "Graphic Agility" && data.requestType === "New") {
                      returnData =  data.count
                   }
                 })
            })
          } else  if(val === 'GrphCo') {
             this.tableContent.map(info =>{
               if(info.cluster === cluster )
                 info.data.map(data =>{
                   if(data.gtmStage === "Graphic Agility" && data.requestType === "Carryover") {
                      returnData =  data.count
                   }
                 })
             })
          }
          return returnData
      },
      hideCol(){
         return this.seasonTrack.split("'")[1] >= 24 && this.seasonTrack != "H1'24"
      }
  }
}

</script>

<style scoped>
.reportTable{
    margin-top: 8vh;
}
table {
     border: 1px outset #bbb;
     color: purple;
     font-size: 20px;
     }
table > * > tr > * { border: 1px inset #bbb; }

thead { background-color: #cacaca; }
tbody { 
    background-color: #ffffff;
     }

table > tbody > tr { text-align: center; }

th { text-align: center; }
.rowWidth th{
     width: 11.25%;
}
</style>